<!-- 登录 -->
<template>
    <div class="login">
        <div class="login-box">
            <div class="login-box-left">
                <div class="applogo-box">
                    <img src="@/static/dzb.png" class="applogo" />
                    <span class="applogo-text">店掌宝·会员收银</span>
                </div>
                <div class="ft-60 text-left color-fff mg-lt100">
                    <div>Hi，您好！</div>
                    <div class="mg-tp30">欢迎进入店掌宝会员收银系统</div>
                </div>
            </div>
            <div class="index">
                <div class="title">请登录</div>
                <div class="input_box">
                    <img src="@/static/account.png" alt="" class="input_img"/>
                    <input type="text" placeholder="请输入您的账号" class="input_index" v-model="uName"/>
                </div>
                <div class="input_box">
                    <img src="@/static/password.png" alt="" class="input_img"/>
                    <input type="password" placeholder="请输入您的密码" class="input_index" v-model="passWord"/>
                </div>
                <div class="forget">
                    <!-- <span @click='goTopassword()'>忘记密码？</span> -->
                    <span></span>
                    <div class="introducer-box">
                        <span class="color-666 ft-22">没有账号？</span>
                        <span class="color-000 ft-22 text-underline" @click="openRegisterModal">去注册></span>
                    </div>
                </div>
                <van-button class="btn" style="margin-top: 4rem;" @click="goTOindex()" :disabled="login" color="linear-gradient(270deg, #1588F5 0%, #1588F5 100%)">登录</van-button>
                <van-button class="btn" style="margin-top: 3rem;" @click="submitForty()" color="#3DB956">体验账号登录</van-button>
            </div>
        </div>

        <!-- 引导弹窗 开始 -->
        <div class="introducer-modal" v-if="isShowRegisterModal">
            <div class="introducer-m-cont">
                <img src="@/static/images/close.png" class="introducer-close absolute" alt srcset @click="closeModal" />
                <div class="introducer-title color-333 ft-28 ft-bold">注册账号</div>
                <div class="introducer-cont">
                    <div class="introducer-text">
                        <p>扫码下载店掌宝掌柜App</p>
                        <p>注册账号后可同时登录收银端与掌柜App</p>
                    </div>
                    <div class="introducer-download-box">
                        <div class="introducer-download">
                            <div class="i-d-text">
                                <img src="@/static/images/ios.png" class="i-d-platform" />
                                <div class="color-fff ft-22">
                                    <p class="mg-bt10">扫码下载</p>
                                    <p>苹果客户端</p>
                                </div>
                            </div>
                            <img src="@/static/images/dzbapp_ios.png" class="dzbapp-ios-img" />
                        </div>
                        <div class="introducer-download mg-lt100" v-if="!is_hidden">
                            <div class="i-d-text">
                                <img src="@/static/images/android.png" class="i-d-platform" />
                                <div class="color-fff ft-22">
                                    <p class="mg-bt10">扫码下载</p>
                                    <p>安卓客户端</p>
                                </div>
                            </div>
                            <img src="@/static/images/dzbapp_android.png" class="dzbapp-ios-img" />
                        </div>
                    </div>
                    <div class="ft-24 text-center color-333 mg-tp30">已完成注册&nbsp;&nbsp;<span @click="closeModal" class="color-FE724F text-underline">去登录</span></div>
                </div>
            </div>
        </div>
        <!-- 引导弹窗 结束 -->
    </div>
</template>

<script>
import {Toast} from "vant";
import config_ from '../../../config.js';
import { connectSunMiScaleService, getScaleData, getScaleDateHcd, getScaleDateSm, isScaleSupported, sunMiScaleDataInfo } from '../../utils/outPrint.js';

export default {
    name: "login",
    data() {
        return {
            uName: null, //登录账号
            passWord: null, //登录密码
            device_code: '', // 唯一设备码
            shop_only_code: '', // 返回的唯一编码
            isShowRegisterModal: false,
            is_hidden: false,
        };
    },
    created() {
        
    },
    mounted() {
        this.getHidden();
    },
    computed: {
        login() {
            return !this.uName || !this.passWord;
        },
    },
    methods: {
        getHidden() {
            this.$api.hidden_register().then(res => {
                this.is_hidden = res.data;
            })
        },
        openRegisterModal() {
            this.isShowRegisterModal = true;
        },
        closeModal() {
            this.isShowRegisterModal = false;
        },
        submitForty() {
            this.$toast.loading({
                message: '体验账号登录中...',
                forbidClick: true,
                duration: 3000
            })
            setTimeout(() => {
                this.uName = 'dzbadmin';
                this.passWord = '123456';
                this.goTOindex(1);
            }, 1000);
        },
        // 跳忘记密码
        goTopassword() {
            this.$router.push({path: "/forgetPassword", query: {}});
        },
        //登录成功跳首页
        goTOindex(type) {
            // 获取uuid
            if (this.$getLocalStorage('device_code')) {
                this.device_code = this.$getLocalStorage('device_code');
            } else {
                this.device_code = device.uuid;
                this.$setLocalStorage("device_code", this.device_code);
            }
            // 登陆接口
            this.shop_only_code = type == 1 ? '67' : localStorage.getItem("shop_only_code");
            console.log('shop_only_code---', this.shop_only_code);
            var data = {};
            // if (!this.shop_only_code) {
                data = {account: this.uName, paw: this.passWord, device_code: this.device_code};
                // Toast("查询到设备是第一次登陆，已初始化，请再次登陆");
            // }
            //  else {
            //     data = {account: this.uName, paw: this.passWord, shop_only_code: this.shop_only_code};
            //     Toast.loading({
            //         message: '登录中...',
            //         forbidClick: true,
            //         duration: 0 //设定持续时间为永久,遇见另一个 Toast 或者使用 clear 方法清除
            //     })
            // }
            this.$api.login(data, 'dontLoad').then((res) => {
                console.log('res', res);
                if (res.code == 1) {
                    localStorage.setItem("token", res.data.list["X-AUTH-TOKEN"]);
                    localStorage.setItem("user_type", res.data.list.user_type);
                    localStorage.setItem("role_str", res.data.list.role_str);
                    localStorage.setItem("shop_id", res.data.list.shop_id);
                    // localStorage.setItem("shop_only_code", res.data.list.shop_only_code);
                    localStorage.setItem('ls_account', this.uName);
                    // 如果没有缓存唯一设备编码，不跳转
                    // if (this.shop_only_code && this.device_code) {
                        Toast.clear();
                        setTimeout(() => {
                            this.$store.dispatch('getStoreInfo');
                            this.initScale();
                        }, 300);
                        this.$router.replace({name: "index", query: {}});
                    // }
                }
            });
        },
        async initScale(){
            const scale_type = localStorage.scale_type;
            if (scale_type == '1') {
                getScaleDateHcd();
            } else if (scale_type == '4') {
                getScaleData();
            } else if (scale_type == '3') {
                setTimeout(async () => {
                    const scaleSupported = await isScaleSupported();
                    this.$store.commit('updateMtScale', scaleSupported);
                    console.log('scaleSupported', scaleSupported);
                    console.log('this.$store.state.mtScale', this.$store.state.mtScale);
                }, 1500);
            } else if (scale_type == '2') {
                await connectSunMiScaleService();
                setTimeout(() => {
                    sunMiScaleDataInfo();
                }, 500);
                getScaleDateSm();
            }
        },
    },
};
</script>

<style scoped lang='less'>
@color: #1588F5;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"]{
    -moz-appearance: textfield;
}

.login {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-image: url("../../static/bg3.jpg");
    // background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.login-box {
    width: 100%;
    height: 100%;
    background: rgba(20, 136, 245, 0.4);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .login-box-left {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        height: 100%;
        .applogo-box {
            position: absolute;
            top: 6rem;
            left: 10rem;
            display: flex;
            align-items: center;
            .applogo {
                width: 8rem;
                height: 8rem;
                margin-right: 2.3rem;
                border-radius: 0.8rem;
            }
            .applogo-text {
                font-size: 3rem;
                color: #fff;
                font-weight: 700;
            }
        }
    }
}

.index {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 0.8rem;
    margin-right: 10rem;
    padding: 4rem 7rem;
    max-height: 83vh;
    overflow-y: scroll;

    .btn {
        width: 55rem;
        height: 8rem;
        border: none;
        border-radius: 4.6rem;
        font-size: 2.8rem;
        font-weight: 500;
        color: #ffffff;
        line-height: 8rem;
    }

    /deep/ .van-button--disabled {
        opacity: 0.8;
    }
}

.title {
    width: 36rem;
    height: 5.6rem;
    font-size: 4rem;
    font-weight: 500;
    color: #1588F5;
    line-height: 5.6rem;
    margin-bottom: 5.2rem;
}

.input_box {
    width: 55rem;
    height: 8rem;
    background: #ffffff;
    border-radius: 4.6rem;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    font-size: 2.6rem;
    border: 0.1rem solid #C9C9C9;
}

.input_img {
    width: 3.6rem;
    height: 3.6rem;
    margin-left: 3.5rem;
    margin-right: 4.2rem;
}

.input_index {
    width: 35rem;
    height: 7rem;
    color: #333;
}

.forget {
    width: 55rem;
    text-align: end;
    font-size: 2.2rem;
    font-weight: 500;
    color: #1588F5;
    line-height: 3.3rem;
    margin-top: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .introducer-box {
        text-align: left;
    }
}
.introducer-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1010;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    .introducer-m-cont {
        width: 82rem;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
        position: relative;
        .introducer-close {
            width: 2.6rem;
            height: 2.6rem;
            right: 2.5rem;
            top: 2.5rem;
        }

        .introducer-title {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
            background: #F2F2F2;
            text-align: center;
            border-radius: 0.8 0.8 0 0;
        }

        .introducer-cont {
            margin: 3rem 8rem 3rem;
            .introducer-text {
                font-size: 2.6rem;
                color: #333;
                line-height: 1.5;
            }

            .introducer-download-box {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2.5rem;

                .introducer-download {
                    .i-d-text {
                        width: 20rem;
                        height: 7.8rem;
                        background: #000;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 3rem 0 2rem;
                        .i-d-platform {
                            width: 5rem;
                            height: 5.3rem;
                        }
                    }

                    .dzbapp-ios-img {
                        width: 25rem;
                        height: 25rem;
                        margin-top: 1.5rem;
                    }
                }
            }
        }
    }
}
</style>
